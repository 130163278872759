<template>
  <div
      id="map"
      class="map"
  />
</template>

<script>
import Vue from 'vue'
import L from '../../node_modules/leaflet'
import {GestureHandling} from 'leaflet-gesture-handling'
import "../../node_modules/leaflet/dist/leaflet.css"
import "../../node_modules/leaflet-fa-markers"
import "../../node_modules/leaflet-fa-markers/L.Icon.FontAwesome.css"
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css"
import PopUpContent from './PopUp'

export default {
  name: "Map",
  props: {
    flyTo:{
      type: Array,
      default: () => ([])
    },
    places:{
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    map: null,
    trainLayerGroup:[],
    lakeLayerGroup:[]
  }),
  methods: {
    initMap () {

      L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)

      this.map = L.map('map', {
        gestureHandling: true,
        attributionControl: false,
        zoomControl: false,
        zoom: 11,
        maxZoom: 18.2,
        center: [50.937531, 6.960278600000038]
      })

      this.tileLayer = L.tileLayer(
          'https://api.mapbox.com/styles/v1/flohman1/ck5mzub2u1a8i1imwx90k3dzv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZmxvaG1hbjEiLCJhIjoiY2luOG1pN3VvMDAzbnY2bHpua2VkZDYzdiJ9.gG_R0ERQIBT9cWDc6Awj8Q',
          {
            attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>'
          })

      this.tileLayer.addTo(this.map)
      this.trainLayerGroup = new L.LayerGroup
      this.lakeLayerGroup = new L.LayerGroup
    },
    setMarkers(Places) {
      let popUpContent = Vue.extend(PopUpContent)
      let propsData = {}
      let icon
      let group
      let popUp

      Places.forEach(p => {
        const trainIcon = L.icon.fontAwesome({
          iconClasses: "fa fa-info-circle",
          markerColor: this.getColor(p.type),
          markerFillOpacity: 0.2,
          markerStrokeWidth: 0,
        })
        propsData = {
          name: p.name,
          adress: p.adress,
          link: p.url
        }
        icon = trainIcon
        group = this.trainLayerGroup
        const lat = p.coords.lat
        const lng = p.coords.lon

        popUp = new popUpContent({
          propsData: propsData
        })

        let m = L.marker([lat, lng], {
          icon: icon
        })
            .addTo(this.map)
            .bindPopup(popUp.$mount().$el)
        group.addLayer(m)
        console.log(m)
      })
    },
    getColor(type){
      switch (type) {
        case 'Trainingsstaette':
          return '#ab191d'
        case 'Treffpunkt':
          return 'blue-grey'
        case 'Schwimmbad':
          return 'cyan'
        case 'See':
          return 'cyan'
      }
    },
  },
  watch: {
    flyTo: function (f) {
      this.map.flyTo(f,16,{animate:true, duration: 1})
      let markers = this.trainLayerGroup.getLayers()
      let marker = markers.find(marker => marker._latlng.lat === f.coords[0] &&  marker._latlng.lng === f.coords[1])
      marker.openPopup()
    }
  },
  mounted () {
    this.initMap()
    this.setMarkers(this.places)

  }
}
</script>

<style scoped>
.map {
  height: 70vh;
  bottom: 5%;
  padding: 0;
  z-index: 1;
}
</style>
