<template>
    <div
            flat
            max-width="200px"
    >
        <span
                class="font-weight-bold pa-0"
                v-text="name"
        />
        <p
                v-if="description"
                v-text="description"
        />
        <p
                v-if="adress"
                v-text="adress"
        />
        <a
                target="_blank"
                class="accent--text"
                v-if="link"
                :href="link"
        >
           {{link}}
        </a>
    </div>
</template>

<script>
    export default {
        name: 'popUp',
        props: ['name', 'adress','description', 'link']
    }
</script>
