<template>
    <kinesis-container event="move">
      <kinesis-element  :strength="0.1" type="scale">
        <v-card
            tile
            class="ma-6 ma-md-3 rounded-lg"
            color="black"
        >
          <v-img
              :src="img(entry.img)"
              :aspect-ratio="ratio"
              gradient="to top, rgba(30,30,30,1) 0%, rgba(0,0,0,0) 20%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container
                style="height: 100%; width: 100%"
                class="d-flex flex-column pa-0"
            >
              <v-row
                  justify="start"
                  class="pa-4"
                  style="position: absolute"
              >
                <v-chip
                    v-for="(sport, i) in entry.typeOfSports"
                    :key="`s-${i}`"
                    class="ma-1"
                    :color="getColor(sport)"
                    text-color="white"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon>{{getIcon(sport)}}</v-icon>
                      </v-avatar>
                    </template>
                    <span>{{$t(`workout.${sport}`)}}</span>
                  </v-tooltip>
                </v-chip>
              </v-row>
              <v-expansion-panels
                  dark
                  flat
                  class="mt-auto"
              >
              <v-expansion-panel>
                <v-expansion-panel-header
                    expand-icon="mdi-chevron-up"
                    color="rgba(23,255,255,0.0)"
                    style="position: absolute;bottom: 0px;overflow: clip"
                >
                  <v-card-title
                      class="text--white title text-sm-subtitle-1 text-lg-h6 text-no-wrap logo pa-2"
                      style="white-space:break-spaces;word-break: break-word"
                  >
                    {{entry.firstName}}
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    class="pb-10 pt-5"
                >
                  <v-row justify="center">
<span
    v-for="(license, i) in entry.license"
    :key="`l-${i}`"
    class="logoLight text-center my-2"

>
              {{ license }}
                </span>
                    <v-divider
                        class="mx-6 col-12"
                        dark
                    />
                    <v-card-text
                        class="text--white text-center"
                    >
                      {{entry.text}}
                    </v-card-text>
                  </v-row>
                  <v-card-actions
                      class="d-flex justify-center"
                  >
                    <v-btn
                        tonal
                        class="logo"
                        rounded="xl"
                        v-text="entry.email"
                        :href="`mailto:${entry.email}`"
                        target=""
                    />
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-img>
        </v-card>
      </kinesis-element>
    </kinesis-container>
</template>

<script>
import i18n from '@//plugins/i18n'
import{KinesisElement, KinesisContainer} from 'vue-kinesis'

export default {
  name: "Coaches",
  components:{
    KinesisContainer,
    KinesisElement
  },
  data: () => ({
    ratio: 0.8
  }),
  props:{
    entry: {
      type: Object,
      default: () => ({
        firstName: 'Max',
        lastName: 'Mustermann',
        img: '',
        license: ['c'],
        typeOfSports: ['triathlon','zwift','cross','youth', 'swim'],
        text: ''
      })
    }
  },
  methods: {
    img(img){
      return img == null ? undefined : img.url
    },
    getIcon (type) {
        switch (type) {
          case 'Athletik':
            return 'mdi-dumbbell'
          case 'Yoga':
            return 'mdi-yoga'
          case 'Zwift':
            return 'mdi-bike'
          case 'Trainingsplaene':
            return 'mdi-checkbook'
          case 'Cross-Triathlon':
            return 'mdi-pine-tree'
          case 'Mountainbike':
            return 'mdi-image-filter-hdr'
          case 'Triathlon':
            return 'mdi-triangle-outline'
          case 'Nachwuchs':
            return 'mdi-account-group'
          case 'Schwimmen':
            return 'mdi-swim'
          case 'Radfahren':
            return 'mdi-bike'
          case 'Laufen':
            return 'mdi-run'
        }
      },
      getColor(type){
        switch (type) {
          case 'Zwift' :
            return 'orange'
          case 'Athletik' :
            return 'blue-grey darken-1'
          case 'Laufen':
            return 'primary'
          case 'Radfahren':
            return 'blue-grey darken-3'
          case 'Schwimmen':
            return 'cyan'
          case 'Yoga':
            return 'green lighten-1'
          case 'Mountainbike':
            return 'green darken-3'
          case 'Cross-Triathlon':
            return 'green darken-1'
          case 'Trainingsplaene':
            return 'grey darken-3'
          case 'Nachwuchs':
            return 'pink'
          default: return 'secondary'
        }
    },
    toggleRatio(){
     if(this.ratio === 0.75) this.ratio = 1.4
      else this.ratio = 0.75
    }
  },
  computed: {
    currentLang () {
      return i18n.locale
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'src/styles/variables.scss'

.v-application
  .display-1
    font-family: $logo
  .title
    font-family: $logo
  .text-md-h4
    font-family: $logo

.logoLight
  font-family: $logoLight
</style>
