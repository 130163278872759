<template>
  <base-card class="pb-5">
    <!--Aspects-->
    <v-container fluid class="d-flex">
    <v-row
        justify="center"
        :no-gutters="$vuetify.breakpoint.xs"
        class="mx-md-1 my-10 my-md-5 align-center"
        :style="$vuetify.breakpoint.mdAndUp ? 'height: 800px' : 'height: 400px'"
    >
      <aspects
          v-for="sport in sports"
          :key="sport.sys.id"
          :entry="sport"
      ></aspects>
    </v-row>
    </v-container>
    <v-parallax
        :height="$vuetify.breakpoint.mdAndUp ? 300 : 300"
        :lazy-src="`${require('@/assets/img/swim.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
        :src="`${require('@/assets/img/swim.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
    >
      <v-overlay
          value="true"
          absolute
      >
        <v-container fluid>
          <v-row justify="center">
            <v-card-title
                id="workouts"
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
                class="justify-center text--white text-uppercase logo mt-6 my-3"
            >
              {{$t('workout.schedule')}}
            </v-card-title>
          </v-row>
        </v-container>
      </v-overlay>
    </v-parallax>
    <v-card
        flat
        tile
        class="mb-10"
    >
      <v-row class="fill-height">
        <v-col>
          <v-overlay
              absolute
              :value="$vuetify.breakpoint.xs"
              class="justify-center align-center d-flex"
          >
            <v-icon>mdi-phone-rotate-landscape</v-icon>
            <span>Rotate Phone</span>
          </v-overlay>
          <v-sheet height="64">
            <v-toolbar
                flat
            >
              <v-toolbar-title v-if="$refs.calendar" class="logoLight">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="auto" width="auto">
            <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :locale="$i18n.locale"
                first-interval="9"
                interval-count="13"
                first-time="09:00"
                :weekdays="weekdays"
                :events="events"
                :event-color="getEventColor"
                event-name="name"
                :type="$vuetify.breakpoint.smAndUp ? 'week' : 'day'"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateRange"
            >
            </v-calendar>
            <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
            >
              <v-card
                  color="grey lighten-4"
                  max-width="500px"
                  flat
              >
                <v-toolbar
                    :color="selectedEvent.color"
                    dark
                >
                  <v-icon
                      class="px-2"
                  >
                    {{getIcon(selectedEvent.type)}}
                  </v-icon>
                  <v-toolbar-title
                      class="text--white justify-center title text-sm-h6 text-lg-h4 logo"
                  >
                    {{selectedEvent.name}}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <p class="black--text logoLight">
                    <span>
                      Wo:
                    </span>
                    <span>
                    <a
                        v-if="selectedEvent.place"
                        @click="goToMap([selectedEvent.place.coords.lat, selectedEvent.place.coords.lon])"
                    >
                      {{ selectedEvent.place.name}}
                    </a>
                  </span>
                  </p>
                  <p class="logoLight">
                    <span class="black--text">mit Wem:</span>
                    <span
                        v-for="(c,i) in selectedEvent.coaches"
                        :key="`c-${i}`"
                        @click="$vuetify.goTo(`#${c.firstName}`,{duration: 800, offset: 10, easing: 'easeInOutQuart'})"
                    >
                    <a
                        class="pl-1"
                    >{{ c.firstName}}</a>
                  </span>
                  </p>
                  <p class="black--text" v-html="selectedEvent.description"></p>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      text
                      color="secondary"
                      @click="selectedOpen = false"
                  >
                    {{$t('workout.close')}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
    <!--Parralax-->
    <v-parallax
        :height="$vuetify.breakpoint.mdAndUp ? 300 : 300"
        :lazy-src="`${require('@/assets/img/roberto_potsdam.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
        :src="`${require('@/assets/img/roberto_potsdam.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
    >
      <v-overlay
          value="true"
          absolute
      >
        <v-container fluid>
          <v-row justify="center">
            <v-card-title
                id="coaches"
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
                class="justify-center text--white text-uppercase logo mt-6 my-3"
            >
              {{$t('workout.coaches')}}
            </v-card-title>
          </v-row>
        </v-container>
      </v-overlay>
    </v-parallax>
    <!--Coaches-->
    <v-container>
      <v-skeleton-loader
          type="paragraph@3"
          :loading="loading"

      >
        <template v-slot:default>
          <v-card-text
              class="text-center text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto mt-3"
              v-html="about"
          >
          </v-card-text>
        </template>
      </v-skeleton-loader>
    </v-container>
    <v-container fluid class="py-5">
      <vue-slick-carousel
          v-if="coaches.length !== 0"
          v-bind="sliderSettings"
          :style="$vuetify.breakpoint.mdAndUp ? 'height: 400px' : 'height: 400px'"
      >
        <coaches
            v-for="coach in coaches"
            :key="coach.sys.id"
            :id=coach.firstName
            :entry="coach"
        />
      </vue-slick-carousel>
    </v-container>
    <!--Parralax-->
    <v-parallax
        :height="$vuetify.breakpoint.mdAndUp ? 300 : 300"
        :lazy-src="`${require('@/assets/img/felipa_berlin.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
        :src="`${require('@/assets/img/felipa_berlin.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
    >
      <v-overlay
          value="true"
          absolute
      >
        <v-container fluid>
          <v-row justify="center">
            <v-card-title
                :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
                class="justify-center text--white text-uppercase logo mt-6 my-3"
            >
              {{$t('workout.facilities')}}
            </v-card-title>
          </v-row>
        </v-container>
      </v-overlay>
    </v-parallax>
    <!--Map-->
    <v-main class="px-0 my-5">
      <base-map
          id="map"
          v-if="places.length !== 0"
          :places="places"
          :fly-to="flyToCoords"
      />
      <!--Places-->
      <v-container fluid class="py-5">
        <vue-slick-carousel
            v-if="places.length !== 0"
            v-bind="sliderSettings"
        >
          <places
              v-for="place in places"
              :key="place.sys.id"
              :id=place.sys.id
              :entry="place"
          />
        </vue-slick-carousel>
      </v-container>
    </v-main>
    <!--Parallax-->
    <v-parallax
        :height="$vuetify.breakpoint.mdAndUp ? 800 : 300"
        :lazy-src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
        :src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
    >
      <v-overlay
          value="true"
          absolute
      >
        <v-container fluid>
          <v-row justify="center">
            <v-col
                class="font-weight-bold white--text text-uppercase headline logo"
            >
              #JEMEINSAM
            </v-col>
          </v-row>
        </v-container>
      </v-overlay>
    </v-parallax>
  </base-card>
</template>

<script>
import Places from "@/components/training/places"
import Coaches from "@/components/training/coaches"
import Aspects from "@/components/training/aspects"
import BaseMap from "@/components/Map"
import {startOfWeek, endOfWeek, eachDayOfInterval, format} from  'date-fns'
import i18n from "@/plugins/i18n"
import {fetchData, query_COACHES, query_SPORT, query_PLACES, query_WORKOUTS, query_WORKOUTABOUT} from "@/utils/api"
import showdown from "showdown";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    BaseMap,
    Coaches,
    Places,
    Aspects,
    VueSlickCarousel
  },
  data: () => ({
    flyToCoords:[1,1],
    focus: '',
    typeToLabel: {
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    weekdays: [1,2,3,4,5,6,0],
    events: [],
    places: [],
    coaches: [],
    sports: [],
    workouts: [],
    about: {},
    loading: true,
    sliderSettings: {
      centerMode: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
  }),
  methods: {
    getAbout: async () => {
      const query = query_WORKOUTABOUT(i18n.locale)
      return fetchData(query)
    },
    getSports: async () => {
      const query = query_SPORT(i18n.locale)
      return fetchData(query, 'typeOfSportCollection')
    },
    getPlaces: async () => {
      const query = query_PLACES(i18n.locale)
      return fetchData(query, 'placesCollection')
    },
    getCoaches: async () => {
      const query = query_COACHES(i18n.locale)
      return fetchData(query, 'coachesCollection')
    },
    getWorkouts: async () => {
      const query = query_WORKOUTS(i18n.locale)
      return fetchData(query, 'workoutCollection')
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    getIcon (type) {
      switch (type) {
        case 'Athletik':
          return 'mdi-dumbbell'
        case 'Yoga':
          return 'mdi-yoga'
        case 'Zwift':
          return 'mdi-bike'
        case 'Trainingsplaene':
          return 'mdi-checkbook'
        case 'Cross-Triathlon':
          return 'mdi-pine-tree'
        case 'Mountainbike':
          return 'mdi-image-filter-hdr'
        case 'Triathlon':
          return 'mdi-triangle-outline'
        case 'Nachwuchs':
          return 'mdi-account-group'
        case 'Schwimmen':
          return 'mdi-swim'
        case 'Radfahren':
          return 'mdi-bike'
        case 'Laufen':
          return 'mdi-run'
      }
    },
    getColor(type){
      switch (type) {
        case 'Zwift' :
          return 'orange'
        case 'Athletik' :
          return 'blue-grey darken-1'
        case 'Laufen':
          return 'primary'
        case 'Radfahren':
          return 'blue-grey darken-3'
        case 'Schwimmen':
          return 'cyan'
        case 'Yoga':
          return 'green lighten-1'
        case 'Mountainbike':
          return 'green darken-3'
        case 'Cross-Triathlon':
          return 'green darken-1'
        case 'Trainingsplaene':
          return 'grey darken-3'
        case 'Nachwuchs':
          return 'pink'
        default: return 'secondary'
      }
    },
    setToday () {
      this.focus = ''
    },
    goToMap (coords) {
      this.$vuetify.goTo('#map',{duration: 800, offset: 10, easing: 'easeInOutQuart'})
      this.flyToCoords = coords
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange () {
      const events = []
      const weekDays= [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
        'Sonntag'
      ]

      const start = startOfWeek(new Date(), {weekStartsOn: 1});
      const end = endOfWeek(new Date(), {weekStartsOn: 1});

      const week = eachDayOfInterval({start:start, end:end})

      for (let i = 0; i < this.workouts.length; i++) {
        const first = `${format(week[weekDays.indexOf(this.workouts[i].weekDay)], 'yyyy-MM-dd')} ${this.workouts[i].workoutStart}`
        const second = `${format(week[weekDays.indexOf(this.workouts[i].weekDay)], 'yyyy-MM-dd')} ${this.workouts[i].workoutEnd}`

        events.push({
          name: this.workouts[i].name,
          start: first,
          end: second,
          color: this.getColor(this.workouts[i].type),
          type: this.workouts[i].type,
          description: this.workouts[i].description,
          place: this.workouts[i].place,
          coaches: this.workouts[i].coachesCollection.items,
          timed: true
        })
      }
      this.events = events
    }
  },
  async created() {
    const about = await this.getAbout()
    const converter = new showdown.Converter()
    this.about = converter.makeHtml(about.textContent.text)
    this.sports = await this.getSports()
    this.coaches = await this.getCoaches()
    this.places = await  this.getPlaces()
    this.workouts = await  this.getWorkouts()
    this.updateRange()
    this.loading = false

    this.$eventHub.$on('locale-changed', async()=> {
      const about = await this.getAbout()
      const converter = new showdown.Converter()
      this.about = converter.makeHtml(about.textContent.text)
      this.coaches = await this.getCoaches()
      this.sports = await this.getSports()
      this.places = await  this.getPlaces()
      this.workouts = await  this.getWorkouts()
      this.updateRange()
    })
  }

}
</script>

<style lang="sass" scoped>
@import 'src/styles/variables.scss'

.v-application
  .display-1
    font-family: $logo
  .title
    font-family: $logo
  .text-md-h4
    font-family: $logo

  .logoLight
    font-family: $logoLight
</style>

