<template>
    <kinesis-container event="move">
      <kinesis-element  :strength="0.1" type="scale">
        <v-card
            tile
            class="ma-6 ma-md-3 rounded-lg"
            min-height="600px"
            dark
        >
          <v-img
              :src="img(entry.img)"
              aspect-ratio="0.75"
              gradient="to top, rgba(30,30,30,1) 0%, rgba(0,0,0,0) 70%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-title
              class="text--white justify-center title text-sm-subtitle-2 text-lg-h6 logo"
              style="white-space:break-spaces;word-break: break-word"
          >
            {{entry.name}}
          </v-card-title>
          <v-divider
              class="mx-6"
              dark
          />
          <v-card-text>
            <v-row
                justify="center"
                class="ma-2"
            >
            <span
                v-for="(license, i) in entry.license"
                :key="`l-${i}`"
                class="logoLight"
            >
              {{ license }}
                </span>
            </v-row>
            <v-row
                justify="start"
            >
              <v-chip
                  v-for="(sport, i) in entry.typeOfSports"
                  :key="`s-${i}`"
                  class="ma-1"
                  :color="getColor(sport)"
                  text-color="white"
              >
                <v-avatar left>
                  <v-icon>{{getIcon(sport)}}</v-icon>
                </v-avatar>
                {{$t(`workout.${sport}`)}}
              </v-chip>
            </v-row>
          </v-card-text>
          <v-card-text
              class="text--white text-center"
          >
            {{entry.description}}
          </v-card-text>
        </v-card>
      </kinesis-element>
    </kinesis-container>
</template>

<script>
import i18n from '@//plugins/i18n'
import{KinesisElement, KinesisContainer} from 'vue-kinesis'

export default {
  name: "Places",
  components:{
    KinesisContainer,
    KinesisElement
  },
  props:{
    entry: {
      type: Object,
      default: () => ({
        name: '',
        type: '',
        url: '',
        description: '',
        adress: '',
        coords: {
          lat: '',
          lon: ''
        },
        img: {
          title: '',
          description: '',
          url: ''
        }
      })
    }
  },
  methods: {
    img(img){
      return img == null ? undefined : img.url
    }
  },
  computed: {
    currentLang () {
      return i18n.locale
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'src/styles/variables.scss'

.v-application
  .display-1
    font-family: $logo
  .title
    font-family: $logo
  .text-md-h4
    font-family: $logo

.logoLight
  font-family: $logoLight
</style>
