<template>
  <v-col
      cols="12"
      sm="3"
  >
        <v-dialog
            transition="dialog-top-transition"
            dark
            :fullscreen="$vuetify.breakpoint.smAndDown"
            style="cursor: pointer"
        >
          <template v-slot:activator="{ on, attrs }">
            <kinesis-container :event="$vuetify.breakpoint.mdAndUp ? 'move' : 'scroll'">
              <kinesis-element  :strength="-0.5" type="scale">
                <v-card
                    tile
                    class="ma-1 ma-md-3 rounded-lg"
                    dark
                    :min-width="[$vuetify.breakpoint.mdAndUp ? '250px' : '50px']"
                >
                <v-img
                    :src="img(entry.img)"
                    :aspect-ratio="[$vuetify.breakpoint.mdAndUp ? 0.8 : 4]"
                    v-bind="attrs"
                    v-on="on"
                    :class="!$vuetify.breakpoint.xs ? 'rounded-lg' : ''"
                    class=" d-flex align-end"
                    :gradient="[$vuetify.breakpoint.mdAndUp ? 'to top, rgba(30,30,30,1) 0%, rgba(0,0,0,0) 30%' : 'to top, rgba(30,30,30,0.6) 0%, rgba(0,0,0,0) 50%']"
                >
                  <v-card-title
                      class="white--text  mt-auto justify-center title text-md-h6 logo"
                  >
                    {{entry.name}}
                  </v-card-title>
                </v-img>
                </v-card>
              </kinesis-element>
            </kinesis-container>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-card-title  class="pa-0">
                <v-img
                    :src="img(entry.img)"
                    max-height="600"
                >
                  <v-row
                      class="fill-height top-gradient pa-0 ma-0"
                      align="center"
                      justify="center"
                  >
                    <kinesis-container event="move">
                      <kinesis-element :strength="10" type="depth">
                    <span
                        :class="$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline'"
                        class="logo"
                    >
                    {{ entry.name }}
                  </span>
                      </kinesis-element>
                    </kinesis-container>
                  </v-row>
                </v-img>
              </v-card-title>
              <v-card-text class="px-4">
                <div class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto mb-5 pt-8">
                  {{ entry.text }}
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="dialog.value = false"
                >Close</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
  </v-col>
</template>

<script>
import i18n from '@//plugins/i18n'
import{KinesisElement, KinesisContainer} from 'vue-kinesis'

export default {
  name: "Coaches",
  components:{
    KinesisContainer,
    KinesisElement
  },
  props:{
    entry: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    img(img){
      return img == null ? undefined : img.url
    },
  },
  computed: {
    currentLang () {
      return i18n.locale
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'src/styles/variables.scss'

.v-application
  .display-1
    font-family: $logo
  .title
    font-family: $logo
  .text-md-h4
    font-family: $logo

.top-gradient
  background-image: linear-gradient(to top, rgba(30,30,30,1) 0%, rgba(0,0,0,0) 100%)


</style>
